import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import SongSearch from '../views/SongSearch.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Song Search',
    component: SongSearch
  },
  {
    path: '/artist',
    name: 'Artist Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ArtistSearch.vue')
  },
  {
    path: '/label',
    name: 'Label Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LabelSearch.vue')
  },
  {
    path: '/youtube',
    name: 'Youtube Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/YoutubeSearch.vue')
  },
  {
    path: '/playlist',
    name: 'Playlist Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlaylistSearch.vue')
  },
  {
    path: '/heritage-search',
    name: 'Heritage reference',
    component: () => import('../views/HeritageSearch.vue')
  },
  {
    path: '/german-search',
    name: 'German Musiktitel DB Search',
    component: () => import('../views/GermanSearch.vue')
  },
  {
    path: '/kellys-search',
    name: 'Gramophone Search',
    component: () => import('../views/GramophoneSearch.vue')
  },
  {
    path: '/mp3-downloader',
    name: 'Mp3 Downloader',
    component: () => import('../views/Mp3Downloader.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
